import { Icon } from '@iconify/react';
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import RangeSlider from 'react-range-slider-input';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterBrand, setFilterModel, setFilterYear } from '../../../redux/reducer/commonReducer';
import Button from '../../../components/element/button';
import { getProductList } from '../../../redux/reducer/serviceReducer';
import { useToast } from '../../../components/common/common.toast';
import { closeFilterModal } from '../../../redux/reducer/modalReducer';

export default function FilterAccordion({ toggleDrawer }) {
    const toast = useToast() 
    const dispatch = useDispatch()
    const { brandList, modelList, filterBrand, filterModel, filterYear } = useSelector((state) => state.common);

    const currentYear = new Date().getFullYear() 

    const [ selectedBrand, setSelectedBrand ] = useState('')
    const [ activeAccordion, setActiveAccordion ] = useState(['0', '2'])

    const generateYearRange = (startYear, endYear) => {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return years;
    };

    const handleOpenAccordion= (eventKey) => {
        if((eventKey === '1' && selectedBrand) || eventKey !== "1") {
            setActiveAccordion((prev) => {
                if (prev.includes(eventKey)) {
                    return prev.filter(prevKey => prevKey !== eventKey);
                } else {
                    return [...prev, eventKey];
                }
            });
        }
    };

    const handleFilterBrand = (name, id) => {
        setActiveAccordion([...activeAccordion, '1']);
    
        const brandExists = filterBrand.some(brand => brand.id === id);
    
        const updatedFilterBrand = brandExists
            ? filterBrand.filter(brand => brand.id !== id)
            : [...filterBrand, { id, name }];
    
        setSelectedBrand(name);
        const modelsForBrand = modelList[name]

        // if (modelsForBrand && modelsForBrand.length > 0) {
        //     if (!brandExists) {
        //         const modelData = modelsForBrand.map(model => ({ id: model.id, name: model.name }))
        //         dispatch(setFilterModel(modelData))
        //     } else {
        //         const updatedFilterModels = filterModel.filter(fModel => !modelsForBrand.some(model => model.id === fModel.id));
        //         dispatch(setFilterModel(updatedFilterModels));
        //     }
        // }
    
        dispatch(setFilterBrand(updatedFilterBrand));
    };

    const handleFilterModel= (name, id, brand) => {
        const modelBrand = brand || selectedBrand;

        const brandExists = filterBrand.some(brand => brand.name === modelBrand);
        const brandId = brandList?.find(brand => brand.name === modelBrand)?.id;

        const modelsForBrand = modelList[modelBrand]
        const allBrandModel = modelsForBrand.map(model => ({ id: model.id, name: model.name, brand: modelBrand }));
        let updatedFilterModel = []

        if(brandExists) {
            updatedFilterModel = allBrandModel.filter(model => model.id !== id)
        } else {
            const modelExists = filterModel.some(model => model.id === id);
            updatedFilterModel = modelExists
                ? filterModel.filter(model => model.id !== id)
                : [...filterModel, { id, name, brand: modelBrand }];
        }

        const allModelsSelected = modelsForBrand?.length > 0 && modelsForBrand.every(model => updatedFilterModel.some(fModel => fModel.id === model.id));
        
        if(allModelsSelected) {
            const updatedFilterBrand = [...filterBrand, { id: brandId, name: modelBrand }];
            dispatch(setFilterBrand(updatedFilterBrand));

            updatedFilterModel = filterModel.filter(fModel => !allBrandModel.some(model => model.id === fModel.id));
        } else {
            const updatedFilterBrand = filterBrand.filter(brand => brand.id !== brandId);
            dispatch(setFilterBrand(updatedFilterBrand));
        }

        dispatch(setFilterModel(updatedFilterModel));
    };

    const handleClearAll = () => {
        dispatch(setFilterBrand([]))
        dispatch(setFilterModel([]))
    }

    const handleApplyFilter = () => {
        if(filterYear[0] >= 1990 && filterYear[1] <= currentYear) {
            dispatch(getProductList({
                service: 'accessory',
                brand_id: filterBrand.map(brand => brand.id),
                model_id: filterModel.map(model => model.id),
                model_year: (filterYear[0] !== 1990 || filterYear[1] !== currentYear) ? generateYearRange(filterYear[0], filterYear[1]) : []
            }))
        } else {
            if(filterYear[0] >= 1990 && filterYear[1] <= currentYear) {
                toast.error(`Min. year should be equal or more than year 1990 and max. year should equal or less than year ${currentYear}`)
            } else if(filterYear[0] >= 1990) {
                toast.error(`Min. year should be equal or more than year 1990`)
            } else {
                toast.error(`Max. year should equal or less than year ${currentYear}`)
            }
        }
    }

    return (
        <>
            <header className='drawer-header'>
                <section className={`flex items-center justify-between ${filterBrand.length > 0 || filterModel.length > 0 ? 'mb-3' : 'mb-1'} ps-4 pe-4 pt-5 pb-5`}>
                    <h5>Filters</h5>
                    {(filterBrand.length > 0 || filterModel.length > 0 || (filterYear[0] !== 1990 || filterYear[1] !== currentYear)) ?
                        <Button btnClassName="apply-button" onClick={() => dispatch(closeFilterModal())}>Apply</Button>
                    :
                        <Icon className="pointer" icon="iconamoon:close" onClick={() => toggleDrawer()} />
                    }
                </section>
            </header>
            {(filterBrand.length > 0 || filterModel.length > 0 || (filterYear[0] !== 1990 || filterYear[1] !== currentYear)) && <>
                <section className="filter-list">
                    {filterBrand.map((fBrand, fBrandIndex) => (
                        <article className="filter-tag" key={fBrandIndex} onClick={() => handleFilterBrand(fBrand.name, fBrand.id)}>
                            <p>{fBrand.name}</p>
                            <Icon icon="iconamoon:close" />
                        </article>
                    ))}
                    {filterModel.map((fModel, fModelIndex) => (
                        <article className="filter-tag" key={fModelIndex} onClick={() => handleFilterModel(fModel.name, fModel.id, fModel.brand)}>
                            <p>{fModel.name}</p>
                            <Icon icon="iconamoon:close" />
                        </article>
                    ))}
                    {(filterYear[0] !== 1990 || filterYear[1] !== currentYear) &&
                        <article className="filter-tag" onClick={() => dispatch(setFilterYear([1990, currentYear]))}>
                            <p>{filterYear[0]} - {filterYear[1]}</p>
                            <Icon icon="iconamoon:close" />
                        </article>
                    }
                </section>
                <section className="clear-all mt-2">
                    <article className="text-end p-4 pb-2 pointer" onClick={() => handleClearAll()}>
                        <p>Clear All</p>
                    </article>
                    <hr />
                </section>
            </>}
            <main>
                <Accordion activeKey={activeAccordion} alwaysOpen flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleOpenAccordion('0')}>
                            Brand
                        </Accordion.Header>
                        <Accordion.Body className="--brand">
                            {brandList.map((brand, brandIndex) => (
                                <section className="flex items-center gap-4" key={brandIndex} onClick={() => handleFilterBrand(brand.name, brand.id)}>
                                    <article className="checkbox-wrapper">
                                        <label>
                                            <input
                                                className="pointer"
                                                type="checkbox"
                                                checked={filterBrand.some(fBrand => fBrand.id === brand.id)}
                                            />
                                            <span className="checkbox"></span>
                                        </label>
                                    </article>
                                    <p>{brand.name}</p>
                                </section>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleOpenAccordion('1')}>
                            Model for&nbsp;<span>{selectedBrand}</span>
                        </Accordion.Header>
                        <Accordion.Body className={`${selectedBrand && modelList[selectedBrand]?.length > 0 ? '--model' : '--model-empty'}`}>
                            {modelList[selectedBrand]?.length > 0 ?
                                modelList[selectedBrand].map((model, modelIndex) => (
                                    <article className={`model-item 
                                        ${filterBrand.some(fBrand => fBrand.name === selectedBrand) || filterModel.some(fModel => fModel.id === model.id)
                                        ? '--selected' : ''}`} 
                                    key={modelIndex} 
                                    onClick={() => handleFilterModel(model.name, model.id)}
                                    >
                                        <p>{model.name}</p>
                                    </article>
                                ))
                            : 
                                <p className="--empty">No available model for this brand</p>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => handleOpenAccordion('2')}>Year</Accordion.Header>
                        <Accordion.Body className="--year">
                            <RangeSlider
                                min={1990}
                                max={currentYear}
                                defaultValue={filterYear}
                                value={filterYear}
                                onInput={(value) => {
                                    dispatch(setFilterYear(value))
                                }}
                            />
                            <article className='year-input'>
                                <section className='w-100'>
                                    <p>Min. Year</p>
                                    <input 
                                        maxLength="4"
                                        value={filterYear[0]}
                                        onChange={(e) => dispatch(setFilterYear([e.target.value, filterYear[1]]))}
                                    />
                                </section>
                                <p className='mb-3'>-</p>
                                <section className='w-100'>
                                    <p>Max. Year</p>
                                    <input 
                                        maxLength="4"
                                        value={filterYear[1]}
                                        onChange={(e) => dispatch(setFilterYear([filterYear[0], e.target.value]))}
                                    />
                                </section>
                            </article>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </main>
        </>
    );
}
