import { Icon } from "@iconify/react";
import { useFormikContext } from "formik";
import {
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function Dropdown({
  label,
  loading,
  name,
  subname,
  type_name,
  vlt_name,
  id_name,
  options,
  placeholder,
  className,
  is_package,
  type_options
}) {
  const { width } = useWindowDimensions()
  const { values, setFieldValue } = useFormikContext();
  const { selectedTintedPackage } = useSelector((state) => state.service);

  useEffect(() => {
    if (options?.length > 0 && is_package) {
      setFieldValue(name, options[0].value);

      if(subname) {
        setFieldValue(subname, parseFloat(options[0].price));
      }
    }
  }, [selectedTintedPackage])

  return (
    <article className={`element _dropdown ${values[name] ? '--active' : ''} ${(is_package && options?.length === 1) || !options || options?.length === 0 || loading ? '--no-option' : ''} ${className ? className : ''}`}>
      <section className="flex">
        {label && <p className="form-label">{label}</p>}
        <MDBDropdown className="w-100" dropup={false}>
          <MDBDropdownToggle type="button" disabled={!options || options?.length === 0 || is_package && options?.length === 1 || loading}>
            {values[name] ?
              <article className="flex flex-wrap justify-between w-100">
                <p className='dropdown-label'>{values[name]}</p>
                {options?.find((option) => option.value === values[name])?.price && (
                  <p className="dropdown-label">+ RM {options?.find((option) => option.value === values[name]).price}</p>
                )}
              </article>
            : 
              <p>
                {loading ? 
                <Icon 
                  icon="eos-icons:three-dots-loading" 
                  width={width > 600 ? 22 : 16} 
                  height={width > 600 ? 22 : 16} 
                  color="#8b8b8b"
                  style={{ marginLeft: '-0.5em' }}
                 /> 
                : !options || options?.length === 0 ? 'No option' 
                : placeholder ? placeholder
                : "- Please Select -"}
              </p>
            }
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            {options &&
              options.map((option, index) => (
                <>
                  <MDBDropdownItem
                    key={index}
                    onClick={() => {
                      if(values[name] !== option.value) {
                        setFieldValue(name, option.value)

                        if(subname) {
                          setFieldValue(subname, parseFloat(option.price));
                        }
                      } else {
                        if(subname) {
                          setFieldValue(name, '')
                          setFieldValue(vlt_name, '')
                          setFieldValue(subname, 0);
                        } else {
                          setFieldValue(name, '')
                        }
                      }

                      if(values[type_name] && values[name] !== option.value && id_name) {
                        setFieldValue(id_name, type_options.find(option => option.value === values[type_name])?.id)
                      } else {
                        setFieldValue(id_name, '')
                      }
                    }}
                  >
                    {index === 0 && <article>
                      <Icon icon="carbon:caret-down" />
                    </article>}
                    <article className="flex flex-wrap justify-between w-100">
                      <p>{option.label}</p>
                      {label === 'Type' && option.price ? <p className={values[name] === option.value ? 'active-label' : ''}>RM {option.price}</p> : ''}
                    </article>
                  </MDBDropdownItem>
                  {index !== (options.length - 1) && <hr className="divider" />}
                </>
              ))}
          </MDBDropdownMenu>
        </MDBDropdown>
      </section>
      {/* {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null} */}
    </article>
  );
}
