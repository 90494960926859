import { useEffect } from "react";
import HeaderImage from "../../components/common/header.image";
import ImageWithText from "../../components/common/image.with.text";
import WeSpecializeIn from "../../components/common/we.specialize.in";
import BusinessPartner from "./components/business.partner";
import CarProduct from "./components/car.product";
import WhyJustint from "./components/why.justint";
import { getProductList } from "../../redux/reducer/serviceReducer";
import { useDispatch, useSelector } from "react-redux";
import AccessoriesModal from "../../components/common/accessories.modal";
import scrollToTop from "../../helpers/scroll.to.top";
import OneStopSolution from "./components/one.stop.solution";

export default function Home() {
    const dispatch = useDispatch()
    const { isOpenAccessoriesModal } = useSelector((state) => state.modal)

    useEffect(() => {
      dispatch(getProductList({
        service: 'accessory',
      }))
    }, [])

    useEffect(() => {
      scrollToTop()
    }, [])

    return (
      <section className="home-page page-padding">
        <HeaderImage locateAt="home" />
        <WeSpecializeIn />
        <WhyJustint />
        <OneStopSolution />
        <CarProduct />
        <ImageWithText locateAt="home" />
        <BusinessPartner />
        {isOpenAccessoriesModal && <AccessoriesModal />}
      </section>
    );
}