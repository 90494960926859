import { LazyLoadImage } from "react-lazy-load-image-component";
import { Partner1, Partner2, Partner3, Partner4, Partner5, Partner6, Partner7 } from "../../../assets/images";

export default function BusinessPartner() {

    const partnerList = [Partner1, Partner2, Partner3, Partner4, Partner5, Partner6, Partner7]

    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px', padding: '20px 0 0' }}>
            <section className="business-partner">
                <article className="partner-text">
                    <h2>Our Business Partner</h2>
                    <h4>Partnering with the best to ensure top-quality service and products</h4>
                </article>
                <article className="partner-list">
                    {partnerList.map((partner, partnerIndex) => (
                        <article className="partner-frame" key={partnerIndex}>
                            <LazyLoadImage 
                                src={partner}
                                alt=""
                            />
                        </article> 
                    ))}
                </article>
            </section>
        </article>
    );
}