import { useDispatch } from "react-redux";
import { setShowModal } from "../../redux/reducer/modalReducer";
import { Icon } from "@iconify/react";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function ModalHeader({
  className,
  title,
  backTo,
  backToNoAnimation,
  shareTo
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions();

  const handleCloseModalWithDelay = () => {
    dispatch(setShowModal(false))
    setTimeout(() => {
      backTo();
    }, 500);
  };

  return (
    <header className={`modal-header ${className ?? ''}`}>
      <article className="flex items-center justify-between w-100">
        <section>
          <article
            onClick={() => { 
              if(backTo) {
                if(backToNoAnimation) {
                  backToNoAnimation()
                } else {
                  handleCloseModalWithDelay()
                }
              }
            }}
          >
            <Icon icon="lets-icons:refund-back" color={backTo ? '#221F1F' : 'transparent'} />
          </article>
        </section>
        <section>
          <h4>
            {title}
          </h4>
        </section>
        <section>
          <article
            onClick={() => { 
              if(shareTo) {
                shareTo()
              }
            }}
          >
            <Icon icon="octicon:share-16" color={shareTo ? '#221F1F' : 'transparent'} />
          </article>
        </section>
      </article>
    </header>
  );
}
