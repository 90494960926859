import { LazyLoadImage } from "react-lazy-load-image-component";
import { AboutHero, HomeHero, SampleHero } from "../../assets/images";
import Button from "../element/button";
import Marquee from "react-fast-marquee";

export default function HeaderImage({ locateAt }) {
    const headerImage = {
        'home': {
            title: 'UNLEASH A NEW LOOK <br /> DEFEND EVERY MILE',
            subtitle: 'PREMIUM PROTECTION • EXPERT CRAFTSMANSHIP • GUARANTEED SATISFACTION',
            button: 'TALK TO US',
            bg_text: 'JUSTINT JUSTINT JUSTINT',
            image: HomeHero
        },
        'about-us': {
            title: 'DRIVEN BY PASSION <br /> FUELED BY EXPERTISE',
            subtitle: 'QUALITY SERVICE • TRUSTED EXPERTISE • LASTING RELATIONSHIPS',
            button: 'LEARN MORE',
            bg_text: 'ESTABLISHED 2005',
            image: AboutHero
        }
    }

    return (
        <article className="w-100">
            <section className={`common-hero --${locateAt}`}>
                <img
                    src={headerImage[locateAt].image}
                    alt={headerImage[locateAt].title}
                />
                <article className="hero-text">
                    <h2 dangerouslySetInnerHTML={{ __html: headerImage[locateAt].title }}></h2>
                    <hr />
                    <p>{headerImage[locateAt].subtitle}</p>
                    <section className="scrolling-text">
                        <Marquee
                            speed={90}
                            autoFill={true}
                            // gradient={true}
                            // gradientWidth={15}
                        >
                            <h1>&nbsp;{headerImage[locateAt].bg_text}&nbsp;</h1>
                        </Marquee>
                    </section>
                    <Button btnClassName="talk-button">{headerImage[locateAt].button}</Button>
                </article>
            </section>
        </article>
    );
}