import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function InputCount({
    className,
    handleUpdateCart,
    current_id,
    current_quantity,
    current_price
}) {
  const { width } = useWindowDimensions()
  const [quantity, setQuantity] = useState(current_quantity);

  const handleAddQuantity = () => {
    handleUpdateCart(current_id, quantity + 1, current_price)
  }

  const handleMinusQuantity = () => {
    if(quantity !== 1) {
      handleUpdateCart(current_id, quantity - 1, current_price)
    }
  }

  const handleChangeQuantity = (e) => {
    handleUpdateCart(current_id, e.target.value, current_price)
  }

  useEffect(() => {
    const inputs = document.querySelectorAll('.input-quantity');

    if(inputs?.length > 0) {
        inputs.forEach((input) => {
            input.style.width = (input.value.length + (width > 600 ? 3 : 1.5)) + 'ch';
        })
    }
  }, [quantity]) 

  return (
    <article className={`element _input-count ${width < 800 ? "w-100 " : ""} ${className ? className : ""}`}>
      <section>
        <article className={`quantity-button ${quantity === 1 ? '--disabled' : ''} pointer`} onClick={() => handleMinusQuantity()}>
            <Icon icon="mingcute:minus-circle-fill" />
        </article>
      </section>
      <section className={width < 800 ? "w-100 text-center" : ""}>
        <input 
            value={quantity} 
            className="input-quantity pointer" 
            onChange={(e) => handleChangeQuantity(e)}
        />
      </section>
      <section>
        <article className="quantity-button pointer" onClick={() => handleAddQuantity()}>
            <Icon icon="mingcute:add-circle-fill" />
        </article>
      </section>
    </article>
  );
}
