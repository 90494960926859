//lib
import axios from 'axios'
import apiService from './api.service';

// function handleErrorResponse(error, toast) {
//   const authError = error.response && error.response.status === 401;
//   const badReqError = error.response && error.response.status === 400;
//   const versionUpdateError = error.response && error.response.status === 410;
//   const manyAttemptsError = error.response && error.response.status === 429;
//   const unexpectedError = error.response && error.response.status === 500;
//   const maintainanceError = error.response && error.response.status === 503;
//   const networkError = !error.response;

//   const merchantName = localStorage.getItem('merchant_name');
  
//   // handle no network
//   if (networkError) {
//     toast.error('Server is busy right now. Please refresh and try again later.');
//   }

//   //handle 401
//   if (authError) {
//     const deviceId = localStorage.getItem('device_id');
//     localStorage.clear();
//     if (deviceId) {
//       localStorage.setItem('device_id', deviceId);
//     }
    
//     setTimeout(() => {
//       window.location = '/';
//     }, 0);
//   }
  
//   //handle 400
//   if(badReqError) {
//     toast.error('This store is closed, please try again later.');
          
//     setTimeout(() => {
//       window.location = `/${merchantName}`;
//     }, 1000);
//   }

//   //handle 410
//   if(versionUpdateError) {
//     toast.success('New version found!')
//     window.location = `?open=update_version`;
//   }

//   // handle 429
//   if(manyAttemptsError) {
//     toast.error('Too many attempts, please try again later.');
//   }

//   // handle 500
//   if (unexpectedError) {
//     toast.error('Oops! Something went wrong unexpectedly. Please contact support for assistance.');
//   }

//   // handle 503
//   if (maintainanceError) {
//     window.location = `?open=under_maintenance`;
//   }

//   return Promise.reject(error);
// }

// function initializeInterceptors(toast) {
//   axios.interceptors.response.use(
//     response => response,
//     error => {
//       Sentry.captureException(error, {
//         extra: {
//           payload: {
//             requestUrl: error?.config?.url,
//             requestData: {
//               method: error?.config?.method,
//               headers: error?.config?.headers,
//               data: error?.config?.data,
//               params: error?.config?.params,
//             },
//             status: error?.response?.status,
//             statusText: error?.response?.statusText,
//             responseData: error?.response?.data,
//           },
//         },
//       });

//       return handleErrorResponse(error, toast);
//     }
//   );

//   axios.interceptors.request.use(
//     config => config,
//     error => {
//       return Promise.reject(error);
//     }
//   );
// }

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token ?? ''}`,
    Accept: `application/json`,
    "user-version": process.env.REACT_APP_VERSION,
  };
}

const httpService = {
  get: axios.get,
  patch: axios.patch,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
//   initializeInterceptors
};

export default httpService
