//lib
import { useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Logo } from "../../assets/images";
import Button from "../element/button";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { closeMenuTab, openLoginModal, openMenuTab } from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../hook/use.window.dimensions";
import { useEffect, useRef } from "react";
import { getToken, getUsername, getUserRank, getUserRole } from "../../redux/reducer/commonReducer";

export default function MainHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuTabRef = useRef(null);
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const { isOpenMenuTab } = useSelector((state) => state.modal);
  const { token, username, role, cartCount } = useSelector((state) => state.common);

  const menuTabList = [
    {
      icon: 'fluent-mdl2:repair',
      label: 'Our Service',
      navigate: 'service/car-tinted'
    },
    {
      icon: 'hugeicons:building-03',
      label: 'About Us',
      navigate: 'about-us'
    },
    {
      icon: 'iconoir:profile-circle',
      label: 'Profile',
      navigate: 'profile'
    },
    {
      icon: 'hugeicons:coins-01',
      label: 'My Commission',
      navigate: 'commission'
    },
  ]

  const handleNavTo = (page) => {
    navigate(`/${page}`)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('role')
    localStorage.removeItem('rank')
    dispatch(getToken())
    dispatch(getUsername())
    dispatch(getUserRole())
    dispatch(getUserRank())
  }

  const handleOpenMenuTab = () => {
    if(token && username) {
      if(isOpenMenuTab) {
        dispatch(closeMenuTab())
      } else {
        dispatch(openMenuTab())
      }
    } else {
      dispatch(openLoginModal())
    }
  } 

  const handleOpenLogin = () => {
    dispatch(openLoginModal())
  }

  const handleClickOutside = (event) => {
    if (menuTabRef.current && !menuTabRef.current.contains(event.target)) {
      dispatch(closeMenuTab())
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(getToken())
    dispatch(getUsername())
    dispatch(getUserRole())
  }, []);

  return (
    <header className={`main-header ${isOpenMenuTab ? 'open' : ''} flex justify-between items-center`}>
      {/* left side */}
      <section className={`flex items-end gap-${width > 991 ? 5 : 4}`}>
        <article className="logo-frame mt-2 pointer" onClick={() => handleNavTo('')}>
          <LazyLoadImage
            src={Logo}
            alt="justint-logo"
          />
        </article>
        <article className="left-side">
          <Button btnClassName="login-button pointer" onClick={() => {
            if(token && username) {
              handleLogout()
            } else {
              handleOpenLogin()
            }
          }}>
            {token && username ? 'Log out' : 'Log in'} 
            {/* Sign out */}
          </Button>
          <article className={`cart-frame ${pathname === '/cart' ? '--active' : ''} pointer`} onClick={() => handleNavTo('cart')}>
            <Icon icon="ph:shopping-cart-simple" />
            <p className="cart-count">{cartCount}</p>
          </article>
          <article className="flex gap-5 mt-2">
            <h5 className={`${pathname.includes('/service') ? '--active' : ''} pointer`} onClick={() => handleNavTo('service/car-tinted')}>Our Service</h5>
            <h5 className={`${pathname === '/about-us' ? '--active' : ''} pointer`} onClick={() => handleNavTo('about-us')}>About Us</h5>
          </article>
        </article>
      </section>
      {/* right side */}
      <section className="flex items-end gap-5">
        <article className="text-end ask-enquiry">
          <h4>Ask For Enquiry</h4>
          <h2>(60)17 448 0830</h2>
        </article>
        <article ref={menuTabRef} className={`menu-bar relative ${isOpenMenuTab ? 'open' : ''} pointer`} onClick={() => handleOpenMenuTab()}>
          <Icon icon="line-md:menu" />
          <section className={`menu-list ${isOpenMenuTab ? 'open' : ''}`}>
              {menuTabList.map((tab, tabIndex) => (
                  ((width > 991 && (role === 'user' ? tabIndex === 2 : tabIndex > 1 ))
                  || width < 991 && (role === 'user' ? tabIndex < 3 : tabIndex < 4 )) && 
                  <article className={`menu-item ${pathname.replace(/^\//, '') === tab.navigate ? 'active' : ''} pointer`} onClick={() => handleNavTo(tab.navigate)} key={tabIndex}>
                      <Icon icon={tab.icon} />
                      <p>{tab.label}</p>
                  </article>
              ))}
          </section>
        </article>
      </section>
    </header>
  );
}
