import { Icon } from "@iconify/react";
import React, { useContext, createContext, useEffect } from "react";
import toast, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";

const ToastContext = createContext();

const ToasterComponent = () => {
    const { toasts } = useToasterStore();
    const TOAST_LIMIT = 1;

    useEffect(() => {
        toasts
            .filter((t) => t.visible) 
            .filter((_, i) => i >= TOAST_LIMIT) 
            .forEach((t) => toast.dismiss(t.id))
    }, [toasts]);

    return (
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 4000,
          }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ message }) => {
                      const { type } = t;

                      return (
                        <article className={`toast-bar --${type}`} onClick={() => toast.dismiss(t.id)}>
                          <section className="toast-divider" />
                          <section className="flex flex-column gap-2 pt-3 pb-3 w-100">
                            <article className="flex justify-between">
                              <h5>{type}</h5>
                              {/* <Icon icon="ic:round-close" /> */}
                            </article>
                            <p>{message}</p>
                          </section>
                        </article>
                      )
                    }}
                </ToastBar>
            )}
        </Toaster>
    );
};

export const CommonToast = ({ children }) => {
    return (
        <ToastContext.Provider value={toast}>
            {children}
            <ToasterComponent />
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);