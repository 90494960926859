import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FrontSideDoor, RearGlass, RearSideDoor, Windscreen } from "../../../assets/images";
import Dropdown from "../../../components/element/dropdown";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import formatWord from "../../../helpers/format.word";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";

export default function ChooseTypeVlt() {
    const { values, validateForm } = useFormikContext()

    const { width } = useWindowDimensions()
    const { 
        selectedTintedPackage, 
        tintedAlacarteList, 
        selectedTintedSeries, 
        selectedTintedType,
        productLoading
    } = useSelector((state) => state.service);
    const { fourBodyTypeList, selectedBodyType } = useSelector((state) => state.common);

    const [ alacarteList, setAlacarteList ] = useState({})

    const carImage = fourBodyTypeList.find(item => item.value === selectedBodyType)?.car_image;

    function getTypeVltOptions(items, isPackage) {
        if (isPackage) {
            return items.properties?.package_detail
                ? Object.entries(items.properties.package_detail).reduce((acc, [key, values]) => {
                    const typeOptions = values.map(item => ({
                        label: item.type,
                        value: item.type,
                        price: item.addon_price || 0,
                        vlt_options: item.VLT.map(vlt => ({
                            label: vlt,
                            value: vlt
                        }))
                    }));
    
                    acc[key] = {
                        type_options: typeOptions
                    };
                    return acc;
                }, {})
                : {};
        } else {
            const acc = {};
            items.length > 0 && items.forEach(item => {
                Object.entries(item.properties.package_detail).forEach(([key, value]) => {
                    if (!acc[key]) {
                        acc[key] = { type_options: [] };
                    }
    
                    value.forEach(detail => {
                        const existingTypeOption = acc[key].type_options.find(option => option.value === detail.type);
                        if (!existingTypeOption) {
                            acc[key].type_options.push({
                                label: detail.type,
                                value: detail.type,
                                price: detail.addon_price || item.price,
                                id: item.id,
                                vlt_options: detail.VLT.map(vlt => ({
                                    label: vlt,
                                    value: vlt
                                }))
                            });
                        } else {
                            // Ensure unique VLT options per type
                            detail.VLT.forEach(vlt => {
                                if (!existingTypeOption.vlt_options.some(option => option.value === vlt)) {
                                    existingTypeOption.vlt_options.push({ label: vlt, value: vlt });
                                }
                            });
                        }
                    });
                });
            });
    
            return acc;
        }
    }

    const typeVltOptions = selectedTintedPackage.name ? getTypeVltOptions(selectedTintedPackage, true) : getTypeVltOptions(alacarteList, false)

    const tintedFormList = [
        {
            label: 'Windscreen',
            image: Windscreen,
            dropdown_list: [
                {
                    label: 'Type',
                    id: 'windscreen_1',
                    name: 'windscreen_type',
                    subname: 'windscreen_price',
                    vlt_name: 'windscreen_vlt'
                },
                {
                    label: 'VLT',
                    id: 'windscreen_2',
                    alacarte_id: 'windscreen_id',
                    name: 'windscreen_vlt',
                    type_name: 'windscreen_type'
                },
            ] 
        },
        {
            label: 'Front Side Door',
            image: FrontSideDoor,
            dropdown_list: [
                {
                    label: 'Type',
                    id: 'front_door_1',
                    name: 'front_door_type',
                    subname: 'front_door_price',
                    vlt_name: 'front_door_vlt'
                },
                {
                    label: 'VLT',
                    id: 'front_door_2',
                    alacarte_id: 'front_door_id',
                    name: 'front_door_vlt',
                    type_name: 'front_door_type'
                },
            ] 
        },
        {
            label: 'Rear Side Door',
            description: 'Total Heat Rejection: 98% / Thickness 3.5 mil / TSER: 58% / UVR: 99%',
            image: RearSideDoor,
            dropdown_list: [
                {
                    label: 'Type',
                    id: 'rear_door_1',
                    name: 'rear_door_type',
                    subname: 'rear_door_price',
                    vlt_name: 'rear_door_vlt',
                },
                {
                    label: 'VLT',
                    id: 'rear_door_2',
                    alacarte_id: 'rear_door_id',
                    name: 'rear_door_vlt',
                    type_name: 'rear_door_type'
                },
            ] 
        },
        {
            label: 'Rear Glass',
            description: 'Total Heat Rejection: 98% / Thickness 3.5 mil / TSER: 58% / UVR: 99%',
            image: RearGlass,
            dropdown_list: [
                {
                    label: 'Type',
                    id: 'rear_glass_1', 
                    name: 'rear_glass_type',
                    subname: 'rear_glass_price',
                    vlt_name: 'rear_glass_vlt',
                },
                {
                    label: 'VLT',
                    id: 'rear_glass_1',
                    alacarte_id: 'rear_glass_id',
                    name: 'rear_glass_vlt',
                    type_name: 'rear_glass_type'
                },
            ] 
        },
    ]

    useEffect(() => {
        if(tintedAlacarteList[selectedTintedSeries]) {
            if(tintedAlacarteList[selectedTintedSeries][selectedTintedType]) {
                if(tintedAlacarteList[selectedTintedSeries][selectedTintedType][selectedBodyType]?.length > 0) {
                    setAlacarteList(tintedAlacarteList[selectedTintedSeries][selectedTintedType][selectedBodyType]) 
                } else {
                    setAlacarteList([])
                }
            } else {
                setAlacarteList([])
            }
        } else {
            setAlacarteList([])
        }
    }, [tintedAlacarteList, selectedTintedSeries, selectedTintedType, selectedBodyType])

    useEffect(() => {
        validateForm();
    }, [values]);

    return (
        <section className="choose-type-vlt relative">
            <article className="car-frame">
                <LazyLoadImage src={carImage} />
            </article>
            <article className="choose-grid">
                {tintedFormList.map((form, formIndex) => (
                    <article className={`section-grid ${width > 550 ? formIndex % 2 === 0 ? '--first' : '--last' : formIndex < 2 ? '--last' : '--first' }`} key={formIndex}>
                        <section className={`section-form ${width > 550 ? formIndex % 2 === 0 ? '--first' : '--last' : formIndex < 2 ? '--last' : '--first' }`}>
                            <article className="section-text">
                                <h6>{form.label}</h6>
                                <p className="--italic">{form.description}</p>
                            </article>
                            {form.dropdown_list.map((dropdown, dropdownIndex) => {
                                return (
                                    <Dropdown 
                                        className='mt-4'
                                        key={dropdownIndex}
                                        id={dropdown.id}
                                        label={dropdown.label}
                                        name={dropdown.name}
                                        subname={dropdown.subname}
                                        type_name={dropdown.type_name}
                                        vlt_name={dropdown.vlt_name}
                                        id_name={dropdown.alacarte_id}
                                        placeholder='Select'
                                        is_package={selectedTintedPackage.name}
                                        loading={productLoading}
                                        type_options={
                                            typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')]?.type_options?.length > 0 ? 
                                            typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')].type_options : []
                                        }
                                        options={dropdown.label === 'Type' ? 
                                          typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')]?.type_options?.length > 0 ? 
                                          typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')].type_options : []
                                        : typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')]?.type_options?.length > 0 ? 
                                          typeVltOptions[form.label.toLowerCase().replace(/\s+/g, '_')]?.type_options
                                          .find(typeOption => typeOption.value === values[dropdown.type_name])?.vlt_options : []
                                        }
                                        validateOnBlur
                                        validateOnChange
                                    />  
                                )
                            })}
                        </section>
                        <section className={`section-image ${width > 550 ? formIndex % 2 === 0 ? '--last' : '--first' : formIndex < 2 ? '--last' : '--first'}`}>
                            <section className='section-frame'>
                                <LazyLoadImage src={form.image} />
                            </section>
                        </section>
                    </article>
                ))}
            </article>
        </section>
    );
}