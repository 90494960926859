import http from "./http.service"

//product
async function getProductList(payload) {
    http.setToken(localStorage.getItem("token"));

    let url = `${process.env.REACT_APP_API_URL}/user_products?service=${payload.service}`;

    if (payload.hasOwnProperty('search')) {
        url += `&search=${payload.search}`;
    }

    if (payload.hasOwnProperty('category_id') && Array.isArray(payload.category_id)) {
        payload.category_id.forEach(id => {
            url += `&category_id[]=${id}`;
        });
    }

    if (payload.hasOwnProperty('brand_id') && Array.isArray(payload.brand_id)) {
        payload.brand_id.forEach(id => {
            url += `&brand_id[]=${id}`;
        });
    }

    if (payload.hasOwnProperty('model_id') && Array.isArray(payload.model_id)) {
        payload.model_id.forEach(id => {
            url += `&car_model_id[]=${id}`;
        });
    }

    if (payload.hasOwnProperty('model_year') && Array.isArray(payload.model_year)) {
        payload.model_year.forEach(year => {
            url += `&model_year[]=${year}`;
        });
    }

    const response = await http.get(url);
    return response.data;
}

async function getProductInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/product/${payload.id}`, payload
    );
    return response.data;
}

//cart
async function addCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/cart/items`,
        payload
    );
    return response.data;
}

async function getCartInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/cart`,
        payload
    );
    return response.data;
}

async function getCartCount(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/cart_item_count`,
        payload
    );
    return response.data;
}

async function updateCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/cart/items/${payload.id}`,
        payload
    );
    return response.data;
}

async function removeCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/cart/items/${payload.id}`,
        payload
    );
    return response.data;
}


//order
async function createOrder(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/orders`,
        payload
    );
    return response.data;
}

async function getOrderList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/orders`,
        payload
    );
    return response.data;
}

async function getCommissionList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/reports/commission_report?type=${payload.type}&length=${payload.length}`,
        payload
    );
    return response.data;
}

//filter
async function getCategoryList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/category?length=${9999}&start=${0}&search=${''}&status=${'active'}`, payload
    );
    return response.data;
}

async function getBrandList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/brand?length=${9999}&start=${0}&search=${''}&status=${'active'}`, payload
    );
    return response.data;
}

async function getModelList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/model?length=${9999}&start=${0}&search=${''}&status=${'active'}`, payload
    );
    return response.data;
}

//profile
async function getProfile(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/profile`, payload
    );
    return response.data;
}

async function updateProfile(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/update_profile`, payload
    );
    return response.data;
}

//claim & insurance
async function claimWindscreen(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/windscreen-claims`, payload
    );
    return response.data;
}


async function renewInsurance(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/insurance-renewals`, payload
    );
    return response.data;
}

// async function clearCart(payload) {
//     // clear whole cart
//     http.setToken(localStorage.getItem("token"));
//     const response = await http.delete(
//         process.env.REACT_APP_API_URL + `/cart/${payload.id}`
//     );
//     return response.data;
// }

// async function updateCart(payload) {
//     http.setToken(localStorage.getItem("token"));
//     const response = await http.put(
//         process.env.REACT_APP_API_URL + `/cart/${payload.cart_id}`,
//         payload
//     );
//     return response.data;
// }

const apiService = {
    getProductList,
    getProductInfo,
    addCart,
    getCartInfo,
    getCartCount,
    updateCart,
    removeCart,
    createOrder,
    getOrderList,  
    getCommissionList, 
    getCategoryList,
    getBrandList,
    getModelList,
    getProfile,
    updateProfile,
    claimWindscreen,
    renewInsurance
};

export default apiService;