import Button from "../../../components/element/button";

export default function AboutCTA() {
    return (
        <article className="w-100 grid center">
            <section className="about-cta">
                <article className="cta-text">
                    <h4>Ready to Experience Excellence?</h4>
                    <p>Discover how our expert services can protect and enhance your vehicle. Let's take your car care to the next level!</p>
                    <Button btnClassName="subscribe-button">Get in Touch Today</Button>
                </article>
            </section>
        </article>
    );
}