import { Icon } from "@iconify/react/dist/iconify.js";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function WeSpecializeIn() {
    const navigate = useNavigate()
    const { pathname } = useLocation(); 
    const { specializeList } = useSelector((state) => state.common);

    const filteredSpecializeList = pathname === '/' 
        ? specializeList.filter(service => service.label !== 'Accessories')
        : specializeList;

    const handleNavTo = (page) => {
        navigate(page)
    }

    return (
        <article className="specialize-in">
            <h3 className="uppercase">We Specializes in</h3>
            <section className="service-list" style={{ gap: "2em" }}>
                {filteredSpecializeList.map((service, serviceIndex) => (
                    <article 
                        className={`service-item ${pathname === service.navigate ? '--active' : ''} pointer`} 
                        onClick={() => handleNavTo(service.navigate)}
                        key={serviceIndex}
                    >
                        <article>
                            <Icon icon={pathname === service.navigate ? service.active_icon ?? service.icon : service.icon} />
                        </article>
                        <p>{service.label}</p>
                    </article>
                ))}
            </section>
        </article>
    );
}