import { LazyLoadImage } from "react-lazy-load-image-component";
import { WhyJustintImg } from "../../../assets/images";
import CommonText from "../../../components/common/common.text";

export default function WhyJustint() {
    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px' }}>
            <section className="why-justint">
                <article className="why-frame">
                    <LazyLoadImage
                        src={WhyJustintImg}
                        alt="why-choose"
                    />
                </article>
                <article className="why-text">
                    <CommonText locateAt='why-justint' />
                </article>
            </section>
            {/* <section className="unknown-section">
              {Array.from({ length: 4 }, (_, watermarkIndex) => (
                <article className="unknown-item">
                    <h2>Title</h2>
                    <p>Description</p>
                </article> 
              ))}
            </section> */}
        </article>
    );
}