import { useDispatch, useSelector } from "react-redux";
import { closeLoginModal } from "../../../redux/reducer/modalReducer";
import FullModal from "../../../components/modal/full.modal.box";
import { MDBModalBody } from "mdb-react-ui-kit";
import Button from "../../../components/element/button";
import { Form, Formik } from "formik";
import { getToken, getUsername, getUserRank, getUserRole, login, sendOtp } from "../../../redux/reducer/commonReducer";
import { useEffect, useState } from "react";
import InputOtp from "../../../components/element/input.otp";
import * as Yup from 'yup';

export default function LoginModal() {
    const dispatch = useDispatch()
    const { isOpenLoginModal } = useSelector((state) => state.modal);
    const { loginLoading, sendOtpLoading } = useSelector((state) => state.common);

    const [ countDown, setCountDown ] = useState(null);
    const [ countDownId, setCountDownId ] = useState(null);
    const [ firstSend, setFirstSend ] = useState(true);

    const loginForm = {
        phone_no: '',
        verification_code: '',
    }

    const loginSchema = Yup.object().shape({
        phone_no: Yup.string()
            .matches(/^[1-9]\d{7,9}$/, 'Username must be a valid phone number')
            .required('Username is required'),
        verification_code: Yup.string()
            .min(6, "OTP must be at least 6 characters")
            .required("Verification code is required"),
    });

    const loginFormList = [
        {
            label: 'Phone Number',
            name: 'phone_no',
            prefix: true
        },
        {
            label: 'OTP',
            name: 'verification_code' 
        },
    ]

    const handleCloseModal = () => {
        dispatch(closeLoginModal())
    }

    const handleSubmit = (values, setFieldError) => {
        dispatch(login({
            username: `+60${values.phone_no}`,
            verification_code: values.verification_code,
            type: 'login'
        }))
        .unwrap()
        .then((res) => {
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('username', res.data.username)
            localStorage.setItem('rank', res.data.user_rank)
            localStorage.setItem('role', res.data.role)

            dispatch(getToken())
            dispatch(getUsername())
            dispatch(getUserRank())
            dispatch(getUserRole())
            dispatch(closeLoginModal())
            // handleBackTo()
            // toast.push(
            //     <Notification type="success">
            //         New car tinted successfully added
            //     </Notification>
            // )
        })
        .catch((ex) => {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        setFieldError(item, errors[item][0]);
                    });
                }
            }
        })
    }

    const handleSendOtp = (values, setFieldError, setFieldValue) => {
        if (firstSend) {
            setFirstSend(false);
        }

        dispatch(sendOtp({
            username: `+60${values.phone_no}`
        }))
        .unwrap()
        .then((res) => {
            setFieldValue(values, values);

            if (countDown === null) {
                setCountDown(60);
                const timer = setInterval(() => {
                setCountDown((prevCountdown) => prevCountdown - 1);
                }, 1000);
                setCountDownId(timer);
            }
        })
        .catch((ex) => {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        setFieldError(item, errors[item][0]);
                    });
                }
            }
        })
    }

    const formatCountDown = (time) => {
        if (time === 60) {
          return `( ${time}s )`;
        } else {
          return `( ${time}s )`;
        }
    };

    useEffect(() => {
        if (countDown === 0) {
          clearInterval(countDownId);
          setCountDown(null);
        }
    }, [countDown, countDownId]);
    
    // useEffect(() => {
    //     if (countDown > 0 && errorStatus === "false") {
    //     } else if (countDown === 0) {
    //       setErrorStatus("");
    //     }
    // }, [countDown]);

    return (
        <FullModal
        staticBackdrop={true}
        show={isOpenLoginModal}
        backButton={handleCloseModal}
        screenSize="xl"
        className="overflow-hidden"
        contentClassName="login-modal"
        content={
          <>
            <MDBModalBody>
              <article className="login-modal">
                <h2>Sign In</h2>
                <Formik
                    initialValues={loginForm}
                    validationSchema={loginSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { setFieldError }) => {
                        handleSubmit(values, setFieldError);
                    }}
                > 
                    {({values, errors, touched, setFieldValue, setFieldError, isValid}) => (
                        <Form className="login-form">
                            {loginFormList.map((form, formIndex) => (
                                <article className="login-input" key={formIndex}>
                                    <h6>{form.label}</h6>
                                    {form.prefix ?
                                        <>
                                            <section className="phone-container">
                                                {/* <article className="phone-wrap"> */}
                                                    <p>+60</p>
                                                    <input
                                                        name={form.name}
                                                        onChange={(e) => {
                                                            setFieldValue(form.name, e.target.value)
                                                        }}
                                                    />
                                                {/* </article> */}
                                                <Button 
                                                    className="h-100"
                                                    btnClassName="send-otp-button"
                                                    disabled={!values.phone_no || errors['phone_no'] || sendOtpLoading}
                                                    onClick={() => {
                                                        if(countDown === null) {
                                                            handleSendOtp(values, setFieldValue, setFieldError)
                                                        }
                                                    }}
                                                >
                                                    <p>{sendOtpLoading ? 'Sending ..' 
                                                    : countDown === null && firstSend ? 'Send OTP' 
                                                    : countDown === null && !firstSend ? 'Send OTP'
                                                    : `Resend in ${formatCountDown(countDown)}`
                                                    }</p>
                                                </Button>
                                            </section>
                                            <section>
                                                {errors &&  errors['phone_no'] ? (
                                                <div className="element _errors text-right no-padding error-message ms-0">
                                                    {errors['phone_no']}
                                                </div>
                                                ) : null}
                                            </section>
                                        </>
                                    :
                                        <InputOtp
                                            name={form.name}
                                        />
                                    }
                                </article>
                            ))}
                            <Button btnClassName="login-button" type="submit" disabled={!values['phone_no'] || !isValid || loginLoading}>Login</Button>
                            {/* <p>How to become our member ── <span>Click here!</span></p> */}
                        </Form>
                    )}
                 </Formik>
              </article>
            </MDBModalBody>
          </>
        }
      />
    );
  }
  