import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import formatNumber from "../../../helpers/format.number";
import { getProductInfo } from "../../../redux/reducer/serviceReducer";
import { openAccessoriesModal, openLoginModal } from "../../../redux/reducer/modalReducer";
import { useToast } from "../../../components/common/common.toast";
import { addCart } from "../../../redux/reducer/cartOrderReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { getCartCount } from "../../../redux/reducer/commonReducer";

export default function AccessoriesBody() {
    const toast = useToast()
    const dispatch = useDispatch()
    const { width } = useWindowDimensions()

    const { token, username } = useSelector((state) => state.common);
    const { addCartLoading } = useSelector((state) => state.cart_order);
    const { selectedCategory, accessoriesList, productLoading } = useSelector((state) => state.service);

    const handleViewProduct = (id) => {
        dispatch(openAccessoriesModal())
        dispatch(getProductInfo({
            id: id
        }))
    }

    const handleAddCart = (e, id) => {
        e.stopPropagation()

        if(token && username) {
            dispatch(addCart({
                product_id: [id],
                quantity: 1
            }))
            .unwrap()
            .then((res) => {
                dispatch(getCartCount())
                toast.success('Add to cart successfully.')
            })
            .catch((ex) => {
                if (ex && ex.response?.status === 422) {
                    const errors = ex.response.data.errors;
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).map((item, i) => {
                            toast.error(errors[item][0]);
                        });
                    }
                }
            })
        } else {
            dispatch(openLoginModal())
        }   
    }

    return (
        <section className="accessories-body">
            <article className="body-wrap">
                <h4>Showing Car Brand for&nbsp;
                    {selectedCategory?.length > 0 && selectedCategory.map((category, categoryIndex) => (
                        <span>{category}{(categoryIndex + 1) !== selectedCategory.length ? ', ' : ''}</span>
                    ))}
                </h4>
                <section className="accessories-list mt-5">
                    {!productLoading ? 
                        accessoriesList?.length > 0 && accessoriesList.map((accessory, accessoryIndex) => (
                            <article 
                                key={accessoryIndex}
                                className="accessories-item pointer" 
                                onClick={() => handleViewProduct(accessory.id)}
                            >
                                <section className="accessories-frame">
                                    <LazyLoadImage src={accessory.media?.length > 0 && accessory.media[0].url} />
                                </section>
                                <section className="accessories-text">
                                    <h6>{accessory.name?.name}</h6>
                                    <p>{accessory.model}</p>
                                    {!isNaN(accessory.price) && <h6 className="--price">RM {formatNumber(accessory.price)}</h6>}
                                </section>
                                <Button 
                                    disabled={addCartLoading}
                                    btnClassName="add-cart-button accessories" 
                                    onClick={(e) => handleAddCart(e, accessory.id)}
                                >
                                    {!isNaN(accessory.price) ? 'Add to Cart' : 'View Price'}
                                </Button>
                            </article>
                        ))
                    : 
                        Array.from({ length: width < 550 ? 2 : 3 }, (_, accessorySkeletonIndex) => (
                            <section className="mt-5" key={accessorySkeletonIndex}>
                                <Skeleton
                                    // width="100%"
                                    height="293px"
                                    className="accessories-item"
                                    key={accessorySkeletonIndex}
                                />
                            </section>
                        ))
                    }
                </section>
            </article>
        </section>
    );
}
