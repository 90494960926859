import { useSelector } from "react-redux";
import DownloadTab from "../../../components/common/download.tab";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Swiper, SwiperSlide } from "swiper/react";

export default function DownloadSeries() {
    const { token, username } = useSelector((state) => state.common);
    const { tintedSeriesList } = useSelector((state) => state.service);

    return (
        <section className={`download-series ${token && username ? '' : '--bottom'}`}>
            <Swiper 
                className="download-list"
                slidesPerView="auto"
                spaceBetween={20}
                grabCursor={true}
            >
                {Object.entries(tintedSeriesList).map(([series], seriesIndex) => (
                    tintedSeriesList[series].map((file, fileIndex) => (
                        <SwiperSlide className="download-item" key={fileIndex}>
                            <LazyLoadImage src={file.file_image} />
                            <DownloadTab title={file.file_name} className="series" />
                        </SwiperSlide>
                    ))
                ))}
            </Swiper>
        </section>
    );
}