import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SampleProduct1, SampleProduct2 } from "../../assets/images";
import apiService from "../../services/api.service";

export const addCart = createAsyncThunk('addCart', async (payload, thunkAPI) => {
  try {
    const response = await apiService.addCart(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getCartInfo = createAsyncThunk('getCartInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getCartInfo(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const updateCart = createAsyncThunk('updateCart', async (payload, thunkAPI) => {
  try {
    const response = await apiService.updateCart(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const removeCart = createAsyncThunk('removeCart', async (payload, thunkAPI) => {
  try {
    const response = await apiService.removeCart(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const createOrder = createAsyncThunk('createOrder', async (payload, thunkAPI) => {
  try {
    const response = await apiService.createOrder(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const getOrderList = createAsyncThunk('getOrderList', async (payload, thunkAPI) => {
  try {
    const response = await apiService.getOrderList(payload);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }}
);

export const initialData = {
  cartCount: 0,
  cartInfo: {},
  selectedCartItem: {},

  orderList: [],
  commissionList: [],
  listLoading: false
};

const dataSlice = createSlice({
  name: "cart_order",
  initialState: initialData,
  reducers: {
    setSelectedCartItem: (state, action) => {
      state.selectedCartItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(addCart.pending, (state) => {
      state.addCartLoading = true;
    })
    .addCase(addCart.fulfilled, (state, action) => {
      state.addCartLoading = false;
    })
    .addCase(addCart.rejected, (state) => {
      state.addCartLoading = false;
    })

    .addCase(getCartInfo.pending, (state) => {
      state.cartLoading = true;
    })
    .addCase(getCartInfo.fulfilled, (state, action) => {
      state.cartLoading = false;
      state.cartInfo = action.payload.data
    })
    .addCase(getCartInfo.rejected, (state) => {
      state.cartLoading = false;
    })
    
    .addCase(updateCart.pending, (state) => {
      state.updateCartLoading = true;
    })
    .addCase(updateCart.fulfilled, (state, action) => {
      state.updateCartLoading = false;
      state.cartInfo = action.payload.data
    })
    .addCase(updateCart.rejected, (state) => {
      state.updateCartLoading = false;
    })

    .addCase(removeCart.pending, (state) => {
      state.removeCartLoading = true;
    })
    .addCase(removeCart.fulfilled, (state, action) => {
      state.removeCartLoading = false;
    })
    .addCase(removeCart.rejected, (state) => {
      state.removeCartLoading = false;
    })

    .addCase(createOrder.pending, (state) => {
      state.createOrderLoading = true;
    })
    .addCase(createOrder.fulfilled, (state, action) => {
      state.createOrderLoading = false;
    })
    .addCase(createOrder.rejected, (state) => {
      state.createOrderLoading = false;
    })

    .addCase(getOrderList.pending, (state) => {
      state.listLoading = true;
    })
    .addCase(getOrderList.fulfilled, (state, action) => {
      state.listLoading = false;
      state.orderList = action.payload.data.aaData
    })
    .addCase(getOrderList.rejected, (state) => {
      state.listLoading = false;
    });
  }
});

export const { 
  setSelectedCartItem
} = dataSlice.actions;

export default dataSlice.reducer;

