import { Form, Formik } from "formik";
import Input from "../../../components/element/input";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect } from "react";
import { getProfile, updateProfile } from "../../../redux/reducer/profileCommissionReducer";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker } from 'antd'
import * as Yup from 'yup'
import Button from "../../../components/element/button";
import moment from "moment";
import { SampleGrey } from "../../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getOrderList } from "../../../redux/reducer/cartOrderReducer";
import Lottie from "lottie-react";
import { Empty, Loading } from "../../../assets/lotties";

export default function OrderList({ locateAt }) {
    const dispatch = useDispatch()
    const { orderList, listLoading } = useSelector((state) => state.cart_order);
    const { token } = useSelector((state) => state.common);

    const orderParts = [
        {
            label: 'Product Orders',
            value: 'order_items',
            type: 'array'
        },
        {
            label: 'Customer Details',
            value: [
                {
                    label: 'Name',
                    value: 'user_name'
                },
                {
                    label: 'Contact No.',
                    value: 'contact'
                },
                {
                    label: 'Car Chassis No.',
                    value: 'car_chassis_number'
                },
                {
                    label: 'Car Plate No.',
                    value: 'car_no_plate'
                },
            ],
            type: 'array'
        },
        {
            label: 'Total Item',
            value: 'number_of_items'
        },
        {
            className: '--highlight',
            type: 'price',
            label: 'Order Total',
            value: 'total_price'
        }
    ]

    useEffect(() => {
        dispatch(getOrderList())
    }, [token])

    return (
        <article className="w-100 flex flex-column items-center" style={{ gap: '50px' }}>
            <section className="order-card">
                <h5>My Order</h5>
                <hr />
                {!listLoading ?
                    <article className="order-list">
                        {orderList?.length > 0 ? 
                            orderList.map((order, orderIndex) => (  
                                <article className="order-item" key={orderIndex}>
                                    <>
                                        <section className="flex flex-wrap items-center justify-between gap-3">
                                            <article className="flex items-center gap-3">
                                                <h6 className="--order-id">Order #{order.invoice_number}</h6>
                                                <article className={`order-status --${order.status === 'appointment set' ? 'appointment' : order.status}`}>
                                                    <p>{order.status}</p>
                                                </article>
                                            </article>
                                            <article className="flex items-center gap-3">
                                                <Icon icon="mdi:date-range" className="--calendar" />
                                                <h6>{order.created_at}</h6>
                                            </article>
                                        </section>
                                        <section className="info-list">
                                            {orderParts.map((part, partIndex) => (
                                                <article className={part.type !== 'array' ? "flex items-center" : ""} key={partIndex}>
                                                    <h6 className={`${part.type === 'array' ? "--order" : ""} ${part.className ? part.className :  ''}`}>
                                                        {part.label} {part.type !== 'array' ? ':' : ''}&nbsp;&nbsp;
                                                    </h6>
                                                    {part.type === 'array' ?
                                                        part.label === 'Product Orders' ?
                                                            order[part.value]?.length > 0 && order[part.value].map((item, itemIndex) => (
                                                                <section className="order-grid" key={itemIndex}>
                                                                    <article className="order-frame">
                                                                        <LazyLoadImage src={item.media?.length > 0 && item.media[0].url} />
                                                                    </article>
                                                                    <article>
                                                                        <h6>{item.product_name}</h6>
                                                                        <span>{item.product_sku_code}</span>
                                                                    </article>
                                                                    <article className="text-center">
                                                                        <h6>{item.quantity}</h6>
                                                                        <span>Qty</span>
                                                                    </article>
                                                                    <article className="text-center">
                                                                        <h6>{item.subtotal}</h6>
                                                                        <span>Price</span>
                                                                    </article>
                                                                </section>
                                                            ))
                                                        :
                                                            <section className="customer-grid">
                                                                {part.value.map((customer, customerIndex) => (
                                                                    <article className="flex justify-between items-baseline w-100" key={customerIndex}>
                                                                        <h6>{customer.label}</h6>
                                                                        <span>{order[customer.value] ?? '-'}</span>
                                                                    </article>
                                                                ))}
                                                            </section>
                                                    :
                                                        <p className={part.className ?? ''}>
                                                            {part.className ? 'RM' : ''}&nbsp;
                                                            {order[part.value]}
                                                        </p>
                                                    }
                                                </article>
                                            ))}
                                        </section>
                                        {(orderIndex + 1) !== orderList.length ?
                                            <hr className="--grey mb-5" /> : <article className="mb-1"></article>
                                        }
                                    </>
                                </article>
                            ))
                        :
                            <article className="w-100 minh-480 flex flex-column items-center">
                                <article className="common-lottie --empty">
                                    <Lottie loop={2} animationData={Empty} />
                                </article>
                                <h6 className="--loading text-center">No data found</h6>
                            </article>
                        }
                    </article>
                : 
                    <article className="w-100 minh-480 flex flex-column items-center">
                        <article className="common-lottie">
                            <Lottie animationData={Loading} />
                        </article>
                        <h6 className="--loading text-center">Loading ..</h6>
                    </article>
                }
            </section>
        </article>
    );
  }
  