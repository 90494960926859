import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { CurveShape, SampleFile } from "../../../assets/images";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { Swiper, SwiperSlide } from "swiper/react";

export default function OurLocation() {
    const { width } = useWindowDimensions();

    const { locationList } = useSelector((state) => state.common);

    return (
        <article className="w-100 flex flex-column items-center">
            <section className="our-location">
                <article className="curve-frame">
                    <LazyLoadImage src={CurveShape} />
                </article>
                <article className="location-list">
                    <section>
                        <h4>Our Location</h4>
                        <p>There's nothing I really wanted to do in life that I wasn't able to get good at. That's my skill.</p>
                    </section>
                    {width > 1300 ?
                        <section className="location-grid">
                            {locationList.map((location, locationIndex) => (
                                <article className="location-item-grid" key={locationIndex}>
                                    <section className="location-frame">
                                        <LazyLoadImage src={SampleFile} />
                                    </section>
                                    <section className="location-text">
                                        <h6>{location.name}</h6>
                                        <p>{location.address}</p>
                                        <p className="--phone-no">{location.phone_no}</p>
                                    </section>
                                </article>
                            ))}
                        </section>
                    :
                        <Swiper
                            className="location-grid"
                            slidesPerView="auto"
                            spaceBetween={20}
                            grabCursor={true}
                        >
                            {locationList.map((location, locationIndex) => (
                                <SwiperSlide className="location-item-grid" key={locationIndex}>
                                    <section className="location-frame">
                                        <LazyLoadImage src={SampleFile} />
                                    </section>
                                    <section className="location-text">
                                        <h6>{location.name}</h6>
                                        <p>{location.address}</p>
                                        <p className="--phone-no">{location.phone_no}</p>
                                    </section>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </article>
            </section>
        </article>
    );
}