import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import ChooseBodyType from "../../../components/common/choose.body.type";
import DownloadTab from "../../../components/common/download.tab";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonPackage from "../../../components/common/common.package";
import { useEffect, useState } from "react";
import CommonStep from "../../../components/common/common.step";

export default function WrappingService() {
    const { wrappingList, wrappingPackageList } = useSelector((state) => state.service);
    const { twoBodyTypeList, selectedBodyType } = useSelector((state) => state.common);

    const [ packageList, setPackageList ] = useState({})

    useEffect(() => {
        if(wrappingPackageList[selectedBodyType]) {
           setPackageList(wrappingPackageList[selectedBodyType])
        } else {
            setPackageList([])
        }
    }, [wrappingPackageList, selectedBodyType])

    return (
        <section className="w-100 flex flex-column items-center" style={{ background: '#131417' }}>
            <article className="wrapping-service">
                <section className="wrapping-text">
                    <h3>Jus-Tint Wrapping Service</h3>
                    <p className="--grey">Jus-tint window films deliver comfort and protection to you and your family at all times</p>
                    <p>Color TPU (Thermoplastic Polyurethane) Paint Protection Film (PPF) for cars offers several benefits, including</p>
                </section>
                <ChooseBodyType bodyTypeList={twoBodyTypeList} className="wrapping" />
                <CommonStep no={2} isSelecting={true} title="Select Alacarte Or Go For Package" subtitle="Available Packages Listed Below" style={{ marginBottom: '-2em' }} />
                <CommonPackage packageList={packageList} className="wrapping" />
                <Swiper 
                    className="wrapping-list"
                    slidesPerView="auto"
                    spaceBetween={20}
                    grabCursor={true}
                >
                    {wrappingList.map((wrapping, wrappingIndex) => (
                        <SwiperSlide key={wrappingIndex} className="wrapping-item">
                            <article className="wrapping-frame">
                                <LazyLoadImage
                                    src={wrapping.image} 
                                    alt={wrapping.label} 
                                />
                            </article>  
                            <article>
                            <h6 className="text-start">{wrapping.label}</h6>
                            <p>{wrapping.description}</p>
                            </article>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <section className="grid center gap-5">
                    <p className="--bottom">High toughness and strong resistance, so that it in scratch resistance and anti-fouling corrosion performance <br />
                    Self-repairing characteristics, but also make it have the scratch repair function that ordinary PVC color changing film does not have, <br />
                    can protect the original car paint, while more stable and durable.</p>
                    <DownloadTab title="Check Out Available Color Films" />
                </section>
            </article>
        </section>
    );
}