//lib
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//asset
import { Logo, SampleSocialMedia } from "../../assets/images";

//redux
import { useSelector } from "react-redux";

export default function Footer() {
  const navigate = useNavigate();

  const { footerList } = useSelector((state) => state.common);

  return (
    <>
      <footer className="footer">
        <section className="footer-list">
          <article className="footer-sublist">
            {footerList.map((footer, footerIndex) => (
              footerIndex <= 2 &&
              <article className="footer-item" key={footerIndex}>
                <p>{footer.label}</p>
              </article>
            ))}
          </article>
          <article className="footer-sublist">
            {footerList.map((footer, footerIndex) => (
              footerIndex === 3 &&
              <article className="footer-frame" key={footerIndex}>
                <LazyLoadImage 
                  src={footer.image}
                  alt={footer.label}
                />
              </article>
            ))}
          </article>
          <article className="footer-sublist">
            {footerList.map((footer, footerIndex) => (
              footerIndex >= 4 &&
              <article className="footer-item" key={footerIndex}>
                <p>{footer.label}</p>
              </article>
            ))}
          </article>
        </section>
        <hr className="footer-line" />
        <section className="social-media-footer">
          <article className="flex gap-4">
            {Array.from({ length: 4 }, (_, socialMediaIndex) => (
              <article className="social-media-frame" key={socialMediaIndex}>
                <LazyLoadImage 
                  src={SampleSocialMedia}
                  alt=""
                />
              </article> 
            ))}
          </article>
          <article>
            <p>© 2024 Justint Sdn. Bhd. (SSM-0001) All rights reserved. We love our users! (Version {process.env.REACT_APP_VERSION})</p>
          </article>
        </section>
      </footer>
    </>
  );
}
